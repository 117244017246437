<template>
  <div>
      <b-card title="Pencarian Jadwal Kerja Karyawan">
        <b-row>
          <b-col md="12">
            <b-row>
              <b-col md="4" sm="12">
                <b-form-group>
                  <v-select
                    v-model="month_periode"
                    :disabled="isSpinner"
                    placeholder="Pilih Bulan"
                    label="monthName"
                    :options="monthPeriode"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" sm="12">
                <b-form-group>
                  <v-select
                    v-model="year_periode"
                    :disabled="isSpinner"
                    placeholder="Pilih Tahun"
                    label="year"
                    :options="yearPeriode"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" sm="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click="getScheduleEmployee()"
                >
                  <b-spinner
                    v-show="isSpinner"
                    class="ml-auto"
                    :hidden="!isSpinner"
                    small
                    type="grow"
                  />
                  <div :hidden="isSpinner">
                    <feather-icon icon="SearchIcon" class="mr-50" />
                    <span class="align-middle">Search</span>
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    <b-row v-show="isTableShow">
      <b-col
        v-if="schedules.length"
        md="12"
        sm="12"
      >
        <b-card>
          <b-row>
            <b-col :hidden="isSpinner">
              <b-table
              responsive="sm"
              :fields="fields"
              :items="schedules"
              >
                <template #cell(schedule_shift_day)="data">
                  {{ schedule_shift_day[0][data.value] }}
                </template>
                <template #cell(schedule_shift_date)="data">
                  {{ data.value | moment("DD-MM-YYYY") }}
                </template>
                <template #cell(shift_name)="data">
                  {{ data.value }}
                </template>
                <template #cell(shift_in_time)="data">
                  {{ data.value }}
                </template>
                <template #cell(shift_out_time)="data">
                  {{ data.value }}
                </template>
              </b-table>
            </b-col>
          </b-row>
          <div
            v-if="isSpinner"
            class="d-flex align-items-center"
          >
            <strong>Loading...</strong>
            <b-spinner
              small
              class="ml-auto"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-show="isNotFound">
      <b-col
        md="12"
      >
        <b-card>
          <b-row>
            <b-col :hidden="isSpinner">
              Data tidak ditemukan
            </b-col>
          </b-row>
          <div
            v-if="isSpinner"
            class="d-flex align-items-center"
          >
            <strong>Loading...</strong>
            <b-spinner
              small
              class="ml-auto"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="v-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      hide-footer
      centered
      title="Peringatan !"
    >
        <b-card-text v-if="isFormRequired">
            Bulan & Tahun harus di isi !!!
        </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import {
  BAlert,
  BModal,
  BBadge,
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BSpinner,
  BOverlay,
  BTable,
  BPagination,
  BInputGroup,
  BFormSelect,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  VBToggle,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import axios from "axios";

export default {
  components: {
    BAlert,
    BModal,
    BBadge,
    BCard,
    BCardTitle,
    BCardText,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BSpinner,
    BOverlay,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BTable,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    heightFade,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isSpinner: false,
      isNotFound: false,
      isTableShow: false,
      isFormRequired: false,
      show: false,
      schedules: [],
      year_periode: [],
      month_periode: [],
      monthPeriode: [
        {
          monthCode: "01",
          monthName: "Januari",
        },
        {
          monthCode: "02",
          monthName: "Februari",
        },
        {
          monthCode: "03",
          monthName: "Maret",
        },
        {
          monthCode: "04",
          monthName: "April",
        },
        {
          monthCode: "05",
          monthName: "Mei",
        },
        {
          monthCode: "06",
          monthName: "Juni",
        },
        {
          monthCode: "07",
          monthName: "Juli",
        },
        {
          monthCode: "08",
          monthName: "Agustus",
        },
        {
          monthCode: "09",
          monthName: "September",
        },
        {
          monthCode: "10",
          monthName: "Oktober",
        },
        {
          monthCode: "11",
          monthName: "November",
        },
        {
          monthCode: "12",
          monthName: "Desember",
        },
      ],
      yearPeriode: [
        {
          yearCode: "2022",
          year: "2022",
        },
        {
          yearCode: "2023",
          year: "2023",
        },
        {
          yearCode: "2024",
          year: "2024",
        },
        {
          yearCode: "2025",
          year: "2025",
        },
        // {
        //   yearCode: "2026",
        //   year: "2026",
        // },
        // {
        //   yearCode: "2027",
        //   year: "2027",
        // },
        // {
        //   yearCode: "2028",
        //   year: "2028",
        // },
        // {
        //   yearCode: "2029",
        //   year: "2029",
        // },
        // {
        //   yearCode: "2030",
        //   year: "2030",
        // },
      ],
      employeeData: JSON.parse(localStorage.getItem("userData")),
      fields:[
        {
          key: 'schedule_shift_day',
          label: 'hari'
        },
        {
          key: 'schedule_shift_date',
          label: 'tanggal'
        },
        { 
          key: 'shift_name',
          label: 'nama shift'
        },
        { 
          key: 'shift_in_time',
          label: 'jam masuk'
        },
        {
          key: 'shift_out_time',
          label: 'jam keluar'
        },
      ],
      schedule_shift_day:[{
        'Monday': 'Senin', 'Tuesday': 'Selasa', 'Wednesday': 'Rabu', 'Thursday': 'Kamis', 'Friday': 'Jumat', 'Saturday': 'Sabtu', 'Sunday': 'Minggu'
      }],
    };
  },
  mounted() {},
  methods: {
    getScheduleEmployee() {
      this.isSpinner = true
      this.show = true
      axios
        .post("employee/myScheduleOnPeriode", {
          employee_code: this.employeeData.employee_code,
          periode_month: this.month_periode.monthCode,
          periode_year: this.year_periode.year,
        })
        .then((response) => {
          console.log(response.data);
          this.isTableShow = true
          this.isNotFound = false
          this.schedules = response.data.data
        })
        .catch((error) => {
          //console.log(error)
          if (error.response.status === 400) {
            console.log(error.response)
            this.$refs['v-modal'].show()
            this.isFormRequired = true
          } 
          if (error.response.status === 404) {
            console.log(error.response)
            this.isTableShow = false
            this.isNotFound = true
          }
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
        })
        .finally(() => {
          this.isSpinner = false
          this.show = false
        })
    },
  },
};
</script>